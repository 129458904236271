import { Injectable } from '@angular/core';
import { ApiService } from '../services/api.service';
import { Observable } from 'rxjs';
import {
  INewUser,
  IPaginateUsers,
  IResendInvite,
  ISetUserRole,
  IUpdateUserImage,
  IVerifyUserCode,
} from '../models/user';

@Injectable({
  providedIn: 'root',
})
export class UsersControllerService {
  private readonly _resourceApi = '/v1/user';

  constructor(private apiService: ApiService) {}

  getUsers<T = any>(): Observable<T> {
    return this.apiService.get<T>(`${this._resourceApi}/all-users`);
  }

  getUserProfile(): Observable<any> {
    return this.apiService.get(`${this._resourceApi}/get-user-profile`);
  }

  autoCompleteUser(body: string): Observable<any> {
    return this.apiService.get(`${this._resourceApi}/search`, { input: body });
  }

  deactivateAccount(id: string): Observable<any> {
    return this.apiService.put(`${this._resourceApi}/disable-account/${id}`);
  }

  resendInvitation(body: IResendInvite): Observable<any> {
    return this.apiService.post(`${this._resourceApi}/resend-invite`, body);
  }

  setUserRole(body: ISetUserRole): Observable<any> {
    return this.apiService.post(`${this._resourceApi}/set-user-role`, body);
  }

  getUserQrAndSecret(id: string): Observable<any> {
    return this.apiService.get(`${this._resourceApi}/getUserQrAndSecret/${id}`);
  }

  inviteUser(params: INewUser): Observable<any> {
    return this.apiService.post(`${this._resourceApi}/invite-user`, params);
  }

  paginateUsers(body: IPaginateUsers): Observable<any> {
    return this.apiService.post(`${this._resourceApi}/get-all-users-pageable`, body);
  }

  search(user: string): Observable<any> {
    return this.apiService.get(`${this._resourceApi}/search`, { input: user });
  }

  updateUser(params: IUpdateUserImage): Observable<any> {
    return this.apiService.put(`${this._resourceApi}/update`, params);
  }

  verify(body: IVerifyUserCode): Observable<any> {
    return this.apiService.post(`${this._resourceApi}/verify-email-code`, body);
  }

  activateAccount(userId: string): Observable<any> {
    return this.apiService.put(`${this._resourceApi}/admin-activate-account`, {}, { userId: userId });
  }

  acceptInvitation(secret: string): Observable<any> {
    return this.apiService.get(`${this._resourceApi}/accept-invitation`, { secret: secret });
  }
}

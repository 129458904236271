import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ILogIn } from '../models/auth';
import { ApiService } from '../services/api.service';

@Injectable({
  providedIn: 'root',
})
export class AuthControllerService {
  private readonly _resourceApi = '/v1/oauth/';

  constructor(private apiService: ApiService) {}

  logIn(body: ILogIn): Observable<any> {
    return this.apiService.get(`${this._resourceApi}login-google`, body);
  }

  logOut(): Observable<any> {
    return this.apiService.get(`/v1/user/log-out`);
  }

  refreshToken(body: string): Observable<any> {
    return this.apiService.get(`${this._resourceApi}refreshToken`, { refreshToken: body });
  }
}

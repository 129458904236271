<div class="flex flex-row max-h-screen">
  <div class="flex">
    <div>
      <img src="assets/images/404.svg" alt="" class="h-screen mr-6" />
    </div>
    <div class="flex flex-column justify-content-center">
      <div class="w-11rem h-3rem box-red-transparent flex align-items-center justify-content-center border-round py-2">
        <p class="text-red font-bold">404 Alert</p>
      </div>
      <div>
        <h1 class="text-6xl mt-4 mb-4">Oh no! Something's missing!</h1>
      </div>
      <p class="text-4xl text-blue-light">This page doesn’t exist, or someone broke it.</p>
      <p class="text-4xl text-blue-light">Please try again later.</p>
      <div class="mt-6">
        <a routerLink="">
          <p-button type="button" label="Back to homepage"></p-button>
        </a>
      </div>
    </div>
  </div>
</div>

import { Injectable } from '@angular/core';
import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { catchError, concatMap, exhaustMap, first, Observable, Subject, throwError } from 'rxjs';
import StorageHelper from '../../core/helpers/storage.helper';
import { AuthControllerService } from '../../core/api/auth-controller.service';
import { ILoginResponse } from '../../core/models/auth';
import { IGenericResponse } from '../../core/models/genericResponse';
import { Router } from '@angular/router';
import { UserService } from '../../core/services/user.service';

@Injectable({
  providedIn: 'root',
})
export class InterceptorService implements HttpInterceptor {
  private _isRefreshing = false;
  private _refreshTokenSubject: Subject<string> = new Subject<string>();

  constructor(
    private authController: AuthControllerService,
    private router: Router,
    private userService: UserService
  ) {}

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(this.setHeaders(request)).pipe(
      catchError((value: HttpErrorResponse) => {
        const error: { error_message: string; message: string; url: string } = value.error as {
          error_message: string;
          message: string;
          url: string;
        };
        if (value.status === 0 || value.status === 502)
          this.router.navigate(['/maintenance'], { skipLocationChange: true });
        if (
          error?.error_message?.includes('The Token has expired') ||
          error?.error_message?.includes("The Token's Signature resulted invalid") ||
          error.message === ''
        ) {
          return this.handleRefreshToken(request, next);
        }
        if (
          (error.message === 'REFRESH_TOKEN_INVALID' && value.url.includes('/v1/oauth/refreshToken')) ||
          error?.error_message === 'USER_DEACTIVATED'
        ) {
          this.userService.logOutUser();
          this._isRefreshing = false;
          this.router.navigate(['/login']);
        }
        return throwError(value);
      })
    );
  }

  handleRefreshToken(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (!this._isRefreshing) {
      this._isRefreshing = true;
      StorageHelper.deleteToken();
      return this.authController.refreshToken(StorageHelper.getRefreshToken()).pipe(
        exhaustMap((response: IGenericResponse<ILoginResponse>): Observable<HttpEvent<any>> => {
          StorageHelper.saveToken(response.response.accessToken);
          this._refreshTokenSubject.next(response.response.accessToken);
          this._isRefreshing = false;
          return next.handle(this.setHeaders(req));
        })
      );
    } else {
      return this._refreshTokenSubject.pipe(
        first(),
        concatMap((): Observable<HttpEvent<any>> => next.handle(this.setHeaders(req)))
      );
    }
  }

  setHeaders(request: HttpRequest<any>): HttpRequest<any> {
    let headers = request.headers.set('Access-Control-Allow-Origin', '*');
    if (StorageHelper.getToken()) {
      headers = headers.set('Authorization', `Bearer ${StorageHelper.getToken()}`);
    }
    return request.clone({ headers });
  }
}

import { Injectable } from '@angular/core';
import StorageHelper from '../helpers/storage.helper';
import { ILoginResponse } from '../models/auth';
import { IUser, User } from '../models/user';
import { Observable, ReplaySubject } from 'rxjs';
import { UsersControllerService } from '../api/users-controller.service';
import { IGenericResponse } from '../models/genericResponse';

@Injectable({
  providedIn: 'root',
})
export class UserService {
  private _userDetails: User;
  private _currentUser: ReplaySubject<User> = new ReplaySubject<User>(1);
  public currentUser$: Observable<User> = this._currentUser.asObservable();
  private _isLoggedIn: ReplaySubject<boolean> = new ReplaySubject<boolean>(1);
  public isLoggedIn$: Observable<boolean> = this._isLoggedIn.asObservable();

  constructor(private userController: UsersControllerService) {}

  logInUser(token: ILoginResponse): void {
    StorageHelper.saveRefreshToken(token.refreshToken);
    StorageHelper.saveToken(token.accessToken);
    this.setCurrentUser();
  }

  logOutUser(): void {
    StorageHelper.deleteToken();
    StorageHelper.deleteRefreshToken();
    this._currentUser.next(null);
    this._isLoggedIn.next(false);
  }

  setCurrentUser(): void {
    if (StorageHelper.getToken()) {
      this.userController.getUserProfile().subscribe((value: IGenericResponse<IUser>) => {
        this._userDetails = new User(value.response);
        this._currentUser.next(this._userDetails);
      });
      this._isLoggedIn.next(true);
    } else this._isLoggedIn.next(false);
  }

  updateUserImage(profileImageUrl: string): void {
    this._userDetails.profileImage = profileImageUrl;
    this._currentUser.next(this._userDetails);
  }
}

export interface IUser {
  userId: string;
  firstName: string;
  lastName: string;
  profileImage: string;
  email: string;
  role: string;
  projectCounter: number;
  twoFactorAuthStatus: boolean;
  accountStatus: boolean;
  userProjects: [];
}

export interface ISetUserRole {
  email: string;
  role: string;
}

export interface IPaginateUsers {
  pageNumber: number;
  pageSize: number;
  projectName: string;
  searchUser: string;
}

export interface IResendInvite {
  email: string;
  firstName: string;
  lastName: string;
}

export class User {
  role: string;
  accountStatus: boolean;
  firstName: string;
  lastName: string;
  projectCounter: number;
  twoFactorAuthStatus: boolean;
  email: string;
  userId: string;
  profileImage: string;
  userProjects: [];

  constructor(user: IUser) {
    this.accountStatus = user.accountStatus;
    this.firstName = user.firstName;
    this.lastName = user.lastName;
    this.role = user.role;
    this.projectCounter = user.projectCounter;
    this.twoFactorAuthStatus = user.twoFactorAuthStatus;
    this.email = user.email;
    this.userId = user.userId;
    this.profileImage = user.profileImage;
    this.userProjects = user.userProjects;
  }
}

export interface INewUser {
  email: string;
  firstName: string;
  lastName: string;
}

export interface IVerifyUserCode {
  code: string;
  userSecret: string;
}

export interface IUpdateUserImage {
  email: string;
  profileImage: string;
}

<div class="flex flex-row h-full overflow-y-hidden">
  <div class="flex">
    <div>
      <img src="assets/images/500.svg" alt="" class="h-screen mr-6" />
    </div>
    <div class="flex flex-column justify-content-center">
      <div
        class="w-15rem h-3rem box-yellow-transparent flex align-items-center justify-content-center border-round py-2">
        <p class="text-yellow font-bold">BRB, We are building!</p>
      </div>
      <div>
        <h1 class="text-6xl mt-4 mb-4">Under maintenance</h1>
      </div>
      <p class="text-4xl text-blue-light">We are fixing some things here and there.</p>
      <p class="text-4xl text-blue-light">Please come back later.</p>
    </div>
  </div>
</div>
